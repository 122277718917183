export const URL_CCP_LOG_PARSER = `https://tools.connect.aws/ccp-log-parser/`;
export const AGGRID_LINCESE = `Using_this_{AG_Grid}_Enterprise_key_{AG-057313}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{CloudHesive}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{ConnectPath}_only_for_{3}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{ConnectPath}_need_to_be_licensed___{ConnectPath}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{29_May_2025}____[v3]_[01]_MTc0ODQ3MzIwMDAwMA==14d9994ed9e27e93e105f300a23cc34d`;
export const UPDATE_STACK = `updateStack`;
export const INTEGRATION_ACTIONS = {
  ADD: `add`,
  EDIT: `edit`,
  FINISH: `finish`,
  TEST: `test`,
  DELETE: `delete`,
  ERROR: `error`,
};
export const STEPS = {
  INITIAL: 0,
  FIRST: 1,
};
export const TYPES = {
  STRING: `string`,
  FUNCTION: `function`,
};
export const CHANNELS_UPPERCASE = {
  TASK: "TASK",
  SMS: "SMS",
};
export const CHANNELS = {
  CHAT: `chat`,
  SMS: `sms`,
  EMAIL: `email`,
  TASK: `task`,
  PHONE: `phone`,
  VIDEO: `video`,
  VOICE: `voice`,
};

export const TIME = {
  AM: "AM",
  PM: "PM",
};
export const NOT_APPLICABLE = "N/A";
export const HOURS_TO_EXPIRE_SESSION = 12;

export const PERMISSION_GRANTED = "granted";
export const IDLE_DETECTOR = "IdleDetector";
export const IDLE_STATE = "idle";
export const LOCKED_SCREEN = "locked";
export const INSTANCE = "instance";
export const ROUTING_PROFILE = "routingProfile";

export const MAX_INTEGRATIONS_PER_QUEUE = 5;

export const VIDEO_CONTROLS = {
  SHARE: "share",
  MIC: "mic",
  AUDIO: "audio",
  VIDEO: "video",
  END: "end",
  DEFAULT_TILE_ID: 1,
};

export const CUSTOM_LINKS = {
  GLOBAL_SCOPE: "Global",
  PRIVATE_SCOPE: "Private",
  DEFAULT_SCOPE: "Global",
};

export const MESSAGE_DIRECTION = {
  IN: "IN",
  OUT: "OUT",
  FWD: "FWD",
};

export const AGGRID_PAGINATION_PAGE_SIZE = 100;
export const AGGRID_PAGINATION_PAGE_SIZE_SELECTOR = [25, 50, 100, 250];
export const AGGRID_ROW_GROUP_PANEL_SHOW = "always";
export const AGGRID_GROUP_DISPLAY_TYPE = "groupRows";
export const AGGRID_AUTO_GROUP_COLUMN_DEF_CELL_RENDERER_PARAMS_INNER_RENDERER = "groupColumnCellRenderer";
export const AGGRID_AUTO_GROUP_COLUMN_DEF_PINNED = "left";
export const AGGRID_THEME_ALPINE_DARK = "ag-theme-alpine-dark";
export const AGGRID_THEME_ALPINE = "ag-theme-alpine";
export const TASK_SOURCE_EXTERNAL = "external";

export const INSTANCE_LOGO_PATH = "dextr/media/logo/logo.jpg";
export const CONTACT_STATES = {
  DISCONNECTED: "disconnected",
  CONNECTED: "connected",
  CONNECTED_ON_HOLD: "CONNECTED_ONHOLD",
  PAUSED: "paused",
};
export const AMAZON_QUICK_RESPONSES = {
  FETCH_ALL_RESPONSES: "",
};

export const ROUTE_ANY_CHANNEL = "ROUTE_ANY_CHANNEL";
export const ERROR_TOO_MANY_CONTACTS = "errorTooManyContacts";

export const CUSTOMER = "CUSTOMER";
export const AGENT = "AGENT";
export const SUPERVISOR = "SUPERVISOR";
export const SYSTEM = "SYSTEM";

export const ERROR = "error";
